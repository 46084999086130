<template>
  <div class="manage">
    <div class="manage_item flex">
      <div class="item-name">绑定手机</div>
      <div class="item-right flex">
        <div>{{phone}}</div>
        <van-icon name="arrow" size="0.5rem" color="#90979c"/>
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  data() {
    return {
      phone: ''
    }
  },
  mounted() {
    this.phone =  common.getItem('phoneNo') ? common.getItem('phoneNo') : '无'
    if (this.phone.length == 11){
      var tel1 = this.phone.substr(0,3) + "****" + this.phone.substr(7)
      this.phone = tel1;
    }
    uwStatisticAction('/manage','账户安全')
  }
}
</script>

<style lang="less" scoped>
.manage{
  background: #f5f5f5;
  height: 100%;
  padding: 0.2rem 0 0;
  .flex{
    display: flex;
  }
  .manage_item{
    background: #fff;
    height: 1rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.01rem solid #ccc;
    .item-name{
      margin-left: 0.3rem;
      font-size: 0.32rem;
    }
    .item-right{
      align-items: center;
      font-size: 0.32rem;
    }
    &:nth-last-child(1){
      border-bottom: 0;
    }
  }
}
</style>
